.infoWrapper {
  width: 100%;
  .oneRedcord {
    .infoDiv {
      display: flex;
      margin-bottom: 1rem;
      .spanDiv {
        min-width: 185px;
        span {
          color: #299bff;
        }
      }
    }
  }
  .actions {
    .status {
      display: flex;
      margin-bottom: 1rem;
      .spanDiv {
        min-width: 185px;
        span {
          color: #299bff;
        }
      }
    }
    .role {
      display: flex;
      .spanDiv {
        min-width: 185px;

        span {
          margin-top: 7px;
          color: #299bff;
        }
      }

      select {
        margin-right: 23px;
      }
    }
  }
  .commission {
    display: flex;
    .spanDiv {
      min-width: 185px;
      span {
        color: #299bff;
      }
    }
    .commissionInput {
      background-color: #121621;
      border-radius: 5px;
      color: white;
    }
  }
}

@media (max-width: 450px) {
  .infoWrapper {
    .oneRedcord {
      .infoDiv {
        display: unset;
      }
    }
    .actions {
      .status {
        display: unset;
      }
      .role {
        display: unset;
        select {
          margin-right: 23px;
        }
      }
    }
  }
}
