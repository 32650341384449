.info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  & > div {
    width: 50%;
    display: flex;
    table {
      --bs-table-bg: var(--bs-body-bg) unset !important;
      --bs-table-color: #fff unset !important;
    }
    span {
      margin-left: 1rem;
      font-size: 22px;
    }
  }
}
