@import '../../styles/variables.scss';

.wrapper {
  .btn {
    background-color: $collapsedDark;
    color: gray;
    border-radius: 2rem;
    cursor: pointer;
    position: relative;
    display: flex;
    width: 130px;
    outline: none;
    border: none;
    font-size: $font-size-normal;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    svg {
      height: 16px;
      width: 16px;
    }
    .imgDeg {
      transform: rotateZ(180deg);
      width: 1.25rem;
      margin-top: 0.3rem;
    }
    img {
      width: 1.25rem;
      margin-top: 0.3rem;
    }
    .down {
      position: absolute;
      right: 10%;
    }
  }
  .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    p {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (max-width: 400px) {
  .wrapper {
    .btn {
      width: 100px;
    }
  }
}
