.logo {
  width: 18rem;
}

.wrapper {
  padding: 50px 0px;
}

.registerBtn {
  background: #299bff !important;
  color: white !important;
}

.footerSpan {
  font-size: 15px;
}
.link {
  text-decoration: none;
  font-size: 15px;
}

.phoneInputCSS {
  margin-bottom: 2rem;
  & > div {
    background-color: transparent !important;
    width: 12%;
    padding-left: 0.5rem;
    & > div:nth-child(2) {
        width: 45px;
        height: 30px;
    }
    & > div:nth-child(3) {
      color: black;
    }
  }
  & > input {
    color: white;
    background-color: #121621;
    border: 0;
    border-radius: 5px;
    height: 2rem;
    &:focus {
      outline: 4px solid #192c5d;
    }
  }
}
