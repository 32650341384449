.title {
  letter-spacing: 1px;
  color: #818ea3;
  font-size: 12px;
}

.header {
  line-height: 1.2rem;
  margin-bottom: 30px;
}

.wrapper {
  .headerBox {
    background: #282c35;
    .logoBox {
        display: flex;
        justify-content: center;
    }
  }
}

.logo {
  width: 18rem;
}

.labelInp {
    color: white;
}

.registerBtn {
    background:#299bff !important;
    color: white !important;
}
