.logo {
    width: 18rem;
}

.footerSpan {
    font-size: 15px;
}
.link {
    text-decoration: none;
    font-size: 15px;
}