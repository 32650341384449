.logo {
    width: 18rem;

}
.headerBox {
    padding-top: 2rem;
}

label {
    font-size: 15px;
}
.link {
    text-decoration: none;
    font-size: 15px;
}

.footerSpan {
    font-size: 15px;
}

.registerBtn {
    background:#299bff !important;
    color: white !important;
}

.inputBgColor {
    background-color: #121621 !important;
    color: white !important;

}

@media (max-width: 500px) {
    .logo {
        width: 10rem;
    }
    .registerBtn {
        font-size: 11px !important;
    }
    .footerSpan, .link {
        font-size: 11px !important;
    }
    .labelInp {
        font-size: 12px !important;
    }
}