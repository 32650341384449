.infoWrapper {
  width: 100%;
  .oneRedcord {
    .infoDiv {
      display: flex;
      margin-bottom: 1rem;
      .spanDiv {
        min-width: 185px;
        span {
          color: #299bff;
        }
      }
    }
  }
}

.extensionsDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  .extInfo {
    width: 50%;
  }
}
