.infoDiv {
  .spanDiv {
    min-width: 185px;
    span {
      color: #299bff;
    }
  }
  .inputs {
    display: flex;
    justify-content: space-between;
    input {
      background-color: #282c35 !important;
    }
  }
}
