.selectDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-direction: column;
  .selectOptionDiv{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
}

.selectBox {
  background-color: #2f3545 !important;
  color: gray;
  margin-top: 4px;
  &:active,
  &:focus {
    background-color: #2f3545 !important;
  }
}

.titleHeader {
  h3 {
    text-align: start !important;
  }
}

.chartBarDiv {
  width: 75%;
  margin: auto;
}

.barBox {
  & > canvas {
    width: 50% !important;
  }
}

.lineChartBox {
  & > :nth-child(1) {
    display: unset !important;
  }
}

.barChartTotal {
  font-size: 1.3rem !important;
}

.barCharPercentage {
  font-size: 1rem !important;
}
.barChartTitle {
  font-size: 0.8rem !important;
}

.agentStats {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

@media only screen and (max-width: 350px) {
    .barBox {
        display: flex !important;
        flex-direction: column !important;
        &>div {
            flex-direction:  column !important;
        }
        & > canvas {
            position: unset !important;
        }
    }
}

