/* Airtouch theme variables*/
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
ul {
  list-style: none;
}
#nprogress .bar {
  background: #e95324 !important;
}
#nprogress .peg {
  box-shadow: 0 0 10px #e95324, 0 0 5px #e95324 !important;
}
#nprogress .spinner-icon {
  border-top-color: #e95324 !important;
  border-left-color: #e95324 !important;
}
.table-full {
  width: 100%;
}
.table-data {
  font-size: 0.8125rem;
  border-collapse: separate;
  font-weight: 300;
}
.table-data.selected thead tr th {
  background: #c0c0c0;
  color: white;
}
.table-data.selected thead tr th .btn {
  color: white;
}
.table-data .btn,
.table-data .btn-default {
  color: #949494;
}
.table-data thead {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  -webkit-appearance: none;
  border-radius: 6px;
  font-weight: 400;
  color: #5d5d5d;
}
.table-data thead tr:first-child th {
  border-top: 1px solid #d0d0d0;
}
.table-data thead tr:first-child th:first-child {
  border-top-left-radius: 7px;
}
.table-data thead tr:first-child th:last-child {
  border-top-right-radius: 7px;
}
.table-data thead tr:last-child th {
  border-bottom: 1px solid #d0d0d0;
}
.table-data thead tr:last-child th:first-child {
  border-bottom-left-radius: 7px;
}
.table-data thead tr:last-child th:last-child {
  border-bottom-right-radius: 7px;
}
.table-data thead tr th:first-child {
  border-left: 1px solid #d0d0d0;
}
.table-data thead tr th:last-child {
  border-right: 1px solid #d0d0d0;
}
.table-data thead tr:last-child th {
  border-bottom: 1px solid #d0d0d0;
}
.table-data tbody {
  color: #6b6b6b;
}
.table-data tbody:before {
  content: '-';
  display: block;
  line-height: 2px;
  color: transparent;
}
.table-data tbody td input {
  background: transparent;
  border: none;
  box-shadow: none;
}
.table-data tbody td:not(:last-child) {
  border-right: 1px solid #d0d0d0;
}
.table-data tbody tr:hover,
.table-data tbody tr.selected {
  color: white;
  background: #d00018;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
}
.table-data tbody tr:hover .btn,
.table-data tbody tr.selected .btn,
.table-data tbody tr:hover .btn-default,
.table-data tbody tr.selected .btn-default {
  color: white;
  background: transparent;
}
.table-data tbody tr:hover .btn:hover,
.table-data tbody tr.selected .btn:hover,
.table-data tbody tr:hover .btn-default:hover,
.table-data tbody tr.selected .btn-default:hover {
  color: white;
  background: transparent;
}
.table-data tbody tr:hover input,
.table-data tbody tr.selected input {
  background: transparent;
  border: none;
  box-shadow: none;
}
.table-data tbody tr:hover input:focus,
.table-data tbody tr.selected input:focus {
  background: white;
  color: #5d5d5d;
}
.table-data tbody tr:hover td:first-child,
.table-data tbody tr.selected td:first-child {
  border-radius: 6px 0 0 6px;
}
.table-data tbody tr:hover td:last-child,
.table-data tbody tr.selected td:last-child {
  border-radius: 0 6px 6px 0;
}
.action-cell {
  width: 100px;
  max-width: 100px;
}
.action-icon {
  margin: 0 5px;
}
