@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
/* ----------------------General rules -------------------------*/

* {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  position: relative;
  min-height: 100%;
  color: white !important;
  font-size: 15px;
  overflow-x: hidden;
  line-height: 1.45em;
  /* background: #F7F7F7;*/
  background-color: #171c28 !important;
  word-wrap: break-word;
}

ul,
li {
  padding: 0;
  margin: 0;
}

i {
  color: #cacedb;
}

a:hover {
  transition: 0.4s;
  color: white !important;
  text-decoration: none;
}

td {
  font-size: 13px;
}

table tbody tr:hover {
  color: #498bf5;
}

form {
  color: black;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #d2d0d6 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #d2d0d6 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #d2d0d6 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #d2d0d6 !important;
}

/* ---------------------- Bootstrap (modified) classes --------------------  */

.input-group-text {
  background: #121621 !important;
}

.form-control {
  font-size: 13px !important;
  background-color: #121621 !important;
  border: 1px solid #0f131b !important;
  color: white !important;
}

.form-control:focus {
  background-color: #121621 !important;
  border: 1px solid #0f131b !important;
  color: white !important;
  outline: 0;
}

.form-control.bordered {
  border: 1px solid gray !important;
  box-shadow: none;
}

.table {
  color: #797979 !important;
  --bs-table-bg: var(--bs-body-bg) unset !important;
  --bs-table-color: #fff unset !important;
}

.table td,
.table th {
  vertical-align: middle;
}

.label-color {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

/* ------------------- Custom classes ------------------------- */

.select-none {
  user-select: none;
}

.hover-el:hover {
  cursor: pointer;
}

/* ----------------------Buttons custom -------------------------*/

.red {
  color: #f44a4a;
}

.white {
  color: white;
}

.mint {
  color: #72edc0;
}

.blue {
  color: #498bf5;
}

.light-grey {
  color: #a2a2a2;
}

.light-grey-bg {
  background-color: #a2a2a2 !important;
}

.lightblue-bg {
  background-color: #299bff !important;
  color: white !important;
}

.lightblue-trans-bg {
  background-color: #299bff70 !important;
}

.lightblue {
  color: #299bff !important;
}

.btn-lightblue {
  background: #299bff !important;
  color: white !important;
}

.btn-lightblue:hover {
  background: #217ecf !important;
  color: white;
}

.btn-lightblue-trans {
  background: #299bffe3;
  color: white;
}

.btn-lightblue-trans:hover {
  background: #1684e4e3;
  color: white;
}

.btn-outline-lightblue {
  color: #299bff !important;
  border-color: #299bff !important;
}

.btn-outline-lightblue:hover {
  color: white !important;
  background: #299bff !important;
  border-color: #299bff !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn i {
  font-size: 15px;
  font-weight: bold;
  color: white;
  position: relative;
  top: 2px;
}

.bt-w-95 {
  width: 95px;
}

.box-shadow-no {
  box-shadow: none !important;
}

.border-gray {
  border: 1px solid #f2efef !important;
}

.link-look {
  cursor: pointer;
  color: #498bf5;
  font-weight: bold;
  font-size: 14px;
}

.link-look:hover {
  color: #157eed;
}

.icon {
  cursor: pointer;
}

.icon:hover path {
  fill: white;
}

.react-icon {
  cursor: pointer;
  &:hover {
    color: white;
  }
}

/* ----------------------Custom Radio input -------------------------*/

.custom-radio-container {
  position: relative;
}

.custom-radio {
  position: absolute;
  visibility: hidden;
}

.custom-radio-container label {
  height: 40px;
  width: 40px;
  z-index: 9;
  position: relative;
  margin: 0;
  cursor: pointer;
}

.check {
  display: block;
  position: absolute;
  border: 2px solid #e8e3e3;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 11px;
  left: 12px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.custom-radio-container:hover .check {
  background: #f5f0ff;
}

.check::before {
  display: none;
  position: absolute;
  content: '';
  left: 7px;
  top: 2px;
  width: 8px;
  height: 14px;
  border: solid #299bff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.custom-radio-container input[type='radio']:checked ~ .check {
  background: #f5f0ff;
}

.custom-radio-container input[type='radio']:checked ~ .check::before {
  display: block;
}

/* ----------------------Custom Checkbox input -------------------------*/

.custom-checkbox {
  position: absolute;
  opacity: 0;
}

.custom-checkbox + label {
  position: relative;
  cursor: pointer;

  color: #777777 !important;
  text-transform: uppercase;
  font-weight: 300 !important;
}

.custom-checkbox + label:before {
  content: '';
  margin-right: 4px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #e3e4e4;
  position: relative;
  top: -1px;
}

.custom-checkbox:hover + label:before {
  background: white;
}

.custom-checkbox:checked + label:before {
  background: white;
}

.custom-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.custom-checkbox:disabled + label::before {
  box-shadow: none;
  background: #ddd;
}

.custom-checkbox:checked + label::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 10px;
  background: #299bff;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #299bff, 4px 0 0 #299bff, 4px -2px 0 #299bff, 4px -4px 0 #299bff,
    4px -6px 0 #299bff, 4px -8px 0 #299bff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-checkbox.empty-label + label::before {
  margin-right: 0;
  margin-bottom: 0;
}

.custom-checkbox.empty-label:checked + label::after {
  top: 11px;
}

.custom-checkbox.empty-label + label {
  top: 3px;
}
/* ----------------------Subtitle list with border bottom -------------------------*/
.subtitle-border-bottom p {
  margin: 0;
}

.subtitle-border-bottom .grey {
  color: grey;
}

.subtitle-border-bottom li {
  border-bottom: 1px solid #4d5363;
  margin-bottom: 14px;
}

.subtitle-border-bottom li span {
  /* color: red; */
  font-weight: bold;
}

/* ----------------------Dinamic class -------------------------*/

.active-plan-card {
  border: 2px solid #c3a7fa !important;
}

.active-group-card {
  background: #faf7ff;
  color: #299bff;
  border-left: 8px solid #8450ef;
  font-weight: bold;
}

/* ----------------------Progress bar -------------------------*/

#nprogress .bar {
  background: #8450ef !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #8450ef, 0 0 5px #9b75e7 !important;
}

#nprogress .spinner-icon {
  border-top-color: #8450ef !important;
  border-left-color: #8450ef !important;
}

/* ----------------------Table -------------------------*/

/* FontAwesome icons */

.th-fa-icons {
  text-align: center;
}

.th-fa-icons i {
  font-size: 16px;
}

.th-fa-icons i:hover {
  cursor: pointer;
  color: #299bff;
  transition: 0.4s;
}

/* Material design icons */

.th-mt-icons {
  text-align: center;
}

.th-mt-icons i {
  font-size: 20px;
}

/* .status-active i {
  color: #72EDC0
}

.status-inactive i {
  color: #F44A4A
} */

.th-fa-icons i:hover {
  cursor: pointer;
  color: #299bff;
  transition: 0.4s;
}

/* ----------------------Forms -------------------------*/

.custom-form-inline {
  display: flex;
}

/* ----------------------Navbar main -------------------------*/

.header-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e6e5e5;
}

.navbar {
  z-index: 1;
}

.navbar-dark {
  color: white;
  font-weight: 600;
  padding: 0;
}

.navbar-brand {
  width: 100%;
}

.navbar-toggler {
  border: none;
}

.nav-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
}

.nav-item.unauth {
  /* justify-content: center; */
  padding-right: 25px;
  border-bottom: 1px solid #eaeaea;
}

.nav-item.nav-right {
  height: 47px;
}

.notification-header {
  padding-right: 15px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.notification-header span {
  position: relative;
}

.notification-header small {
  position: absolute;
  background: #f44a4a;
  color: white;
  font-size: 9px;
  width: 16px;
  height: 15px;
  border-radius: 50%;
  line-height: 15px;
  text-align: center;
  left: 16px;
  top: 10px;
  font-weight: bold;
}

.dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-email {
  font-size: 12px;
  margin: 0;
  color: #797979;
}

.dropdown-link span:first-child {
  border-right: 1px solid #d2c4fb;
  border-left: 1px solid #d2c4fb;
  margin-right: 8px;
  padding: 0 15px;
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdown-link span:nth-child(2) i {
  position: relative;
  top: 2px;
}

.dropdown-link:hover {
  color: #498bf5;
}

.dropdown-link .material-icons {
  color: #299bff;
}

.dropDown {
  width: 200px;
  position: absolute;
  background: #171c28;
  display: block;
  right: -10px;
  top: 50px;
  box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
}

.dropDown .dropdown-item:hover {
  color: black !important;
}

.dropDown hr {
  margin-top: 3px;
  margin-bottom: 3px;
}

.dropDown .logout {
  color: #8450ef;
}

.dropDown i {
  font-size: 14px;
  color: #cacedb;
  position: relative;
  top: 3px;
}

.dropDown .dropdown-item {
  color: #797979;
}

.dropdown-item.logout {
  color: #f44a4a;
}

.img-logo {
  max-height: 35px !important;
  display: block;
  margin: 10px auto;
}

/* ----------------------Office page-------------------------*/

.office-page-container .card-header-custom {
  display: flex;
  align-items: center;
  padding: 33px 32px;
  justify-content: space-between;
}

/* ----------------------Profile page-------------------------*/

.profile-page-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile {
  background-color: #121621;
  width: 700px;
  border-radius: 10px;
  height: 510px;
}

.profile .subtitle-border-bottom {
  padding: 5px 0;
}

.profile .subtitle-border-bottom li {
  padding-bottom: 10px;
}

.profile-page-container .page-title {
  color: #6c7486;
}

.profile-page-container .border-bottom {
  border-bottom: 2px solid #434447 !important;
  margin-bottom: 32px;
}

/* ----------------------Login and register-------------------------*/

.auth-page-container {
  background: #121621;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  text-align: center;
  background-position: center;
  padding: 140px 0;
  color: white;
}

.auth-page-container form {
  color: white;
}

.auth-frame {
  background: #171c28;
  margin-top: 70px;
  margin: 5px 20px;
  color: white;
}

.auth-img-box {
  margin-bottom: 27px;
  border-bottom: 1px solid #cacfd1;
  padding-bottom: 17px;
}

.auth-logo {
  max-width: 150px;
}

.auth-footer {
  margin-top: 20px;
  border-top: 1px solid #cacfd1;
  font-weight: 100;
  text-align: left;
}

.auth-footer a {
  color: #299bff;
}

/* ----------------------Dashboard page-------------------------*/

.container-fluid.main-wrapper {
  // margin-bottom: 100px;
}
.page-title {
  color: white;
}

.card-header-custom span.trash {
  height: 24px;
  width: 24px;
}

.page-header .page-subtitle {
  letter-spacing: 1px;
  color: #818ea3;
  font-size: 12px;
}

/* -----------------------Dashboard left side navbar -------------------*/

.dashboard-navbar .activeNavLink {
  padding: 0rem 1.25rem;
  color: white;
  box-shadow: rgb(46, 141, 241) 3px 0px 0px inset;
  background: #299bff;
}

.dashboard-navbar .activeNavLinkReactIcons {
  padding: 0rem 1.25rem;
  box-shadow: rgb(46, 141, 241) 3px 0px 0px inset;
  background: #299bff;
  color: white;
}

.activeNavLink path {
  fill: white;
}

.dashboard-navbar .title {
  text-align: center;
  font-size: 30px;
  margin-top: 32px;
  margin-bottom: 81px;
}

.dashboard-navbar {
  position: relative;
  background-color: #121621;
  transition: all 0.2s ease;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: all 0.2s ease;
  height: 100vh;
  /* box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.5rem rgba(90, 97, 105, 0.12), 0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1), 0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1); */
}

.dashboard-navbar li a {
  color: #797979;
  width: 100%;
  padding: 0rem 1.25rem;
  transition: 0.3s;
  text-align: center;
}

.dashboard-navbar li i {
  font-size: 19px;
  margin-right: 5px;
}

.dashboard-navbar svg {
  display: block;
  height: 25px;
  width: 25px;
  margin: auto;
}

.dashboard-navbar li a:hover {
  transition: 0.3s;
  color: #498bf5;
  /* background: linear-gradient(-135deg, rgba(255, 255, 255, 0), rgb(223, 227, 255)); */
}

.dash-navbar {
  margin-top: 32px;
  padding-left: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dash-navbar li:last-child {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.dash-navbar p {
  margin-top: 0px;
  margin-bottom: 8px;
}

/* ----------------------Charts-------------------------*/

.card-charts-title {
  display: flex;
  justify-content: space-between;
}

.card-charts-title h6 {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 2px;
  color: grey;
  margin: 5px 0;
}

.card-charts-title select,
.card-charts-title select:focus {
  background-color: #2f3545;
  color: grey;
  border: 0;
  outline: 0;
  box-shadow: none;
}

.card-charts-title select {
  width: initial;
  cursor: pointer;
  font-size: 13px;
}

.charts-line-wrapper {
  /* height: 316px; */
  position: relative;
}

.charts-line-wrapper canvas,
.charts-pie-wrapper canvas {
  height: 100% !important;
  width: 100% !important;
}

.line-charts-box {
  padding: 0;
  display: flex;
  position: relative;
  align-items: center;
}

.line-charts-box canvas {
  position: absolute;
  right: 0;
  height: 55px !important;
  width: 152px !important;
}

.line-charts-heading {
  z-index: 999;
}

.line-charts-title {
  letter-spacing: 0.0625rem;
  color: #818ea3;
  font-size: 13px;
  text-transform: uppercase;
}

.line-charts-total {
  font-weight: 500;
  margin: 6px 0;
  font-size: 26px;
}

.green-increase,
.green-increase i {
  color: green;
}

.red-decrease,
.red-decrease i {
  color: red;
}

/* ----------------------Custom card style-------------------------*/

.card-header-custom h4 {
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 1px;
  color: #299bff;
  font-size: 20px;
  margin: 6px 0;
}

.card-list-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e5e5;
}

.card-list-item h6 {
  font-weight: 100;
}

.card-list-item p {
  font-weight: 500;
}

.card-list-btns {
  text-align: center !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.card-list-btns button {
  width: 100%;
  margin-bottom: 9px;
}

.card-custom.card {
  background-color: #121621;
  color: white;
  border: none;
  border-radius: 0.625rem;
  /* box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1); */
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  background: transparent;
}

.card-body {
  padding: 1.25rem 0.5rem;
}

.card-list-btns button {
  width: initial;
  margin-right: 10px;
}

.card-list-item {
  border-bottom: none;
  margin-bottom: 0px;
}

.card-list-items {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 34px;
  text-align: center;
}

.card-body-list .button-group {
  padding: 0 1.25rem;
}

.card-custom .border-bottom {
  border-bottom: 2px solid #434447 !important;
}

/* ----------------------Plan page-------------------------*/

.card-plan {
  margin-bottom: 20px;
}

.plan-card-name span {
  font-weight: bold;
  font-size: 17px;
  border-radius: 50%;
  /* border: 2px solid #9768f2; */
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 39px;
  background: #e3d5fd;
  color: #8450ef;
}

.plan-card-box {
  display: flex;
  align-items: center;
}

.plan-card-name {
  width: 67px;
}

.plan-card-desc {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.plan-card-desc p {
  color: #868686;
}

.curent-plan-options {
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 38px;
  padding-top: 36px;
  border-bottom: 1px solid #e8e3e3;
  border-top: 1px solid #e8e3e3;
}

.curent-plan-options p {
  color: #797979;
  font-size: 14px;
}

.curent-plan-right p {
  margin-top: 10px;
  font-weight: 400;
}

.btn-pay-fixed {
  margin-bottom: 20px;
}

/* ----------------------Create new organizations -------------------------*/
.margin-top-10 {
  margin-top: 10px;
}

.input-code {
  position: relative;
}

.input-code span {
  border: 1px solid #cad6e2;
  border-right: none;
  position: absolute;
  background: #e9ecef;
  width: 52px;
  height: 34px;
  line-height: 35px;
  padding: 0 3px;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
}

.input-code input {
  padding-left: 60px;
}

/* ----------------------Steper -------------------------*/
.steper-wrapper {
  margin: 30px 0 50px 0;
}
.step-item {
  display: flex;
  align-items: center;
  position: relative;
}
.step-item h6 {
  margin: 0;
  border-bottom: 1px solid #dedddd;
  padding: 6px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
}

.step-circle span {
  width: 40px;
  height: 40px;
  background: #299bff;
  display: inline-block;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  margin: 10px;
}
.done-true {
  display: block;
}
.done-false {
  display: none;
}
.circle-grey {
  background: #dcd5f3;
}

.step-circle span i {
  position: relative;
  top: 3px;
  color: white;
  font-size: 23px;
}

/* ---------------------- Modal  -------------------------*/
.selected-plan-modal {
  padding: 10px;
}

.braintree-sheet__error {
  padding: 1rem !important;
}

.modal-content {
  color: white;
  background-color: #121621 !important;
}

.modal-content span {
  color: white;
}

.modal-light .modal-body,
.modal-light .modal-header {
  background: white;
}

.modal-light .modal-content {
  color: black;
}

.modal-light .modal-content span {
  color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8f9bb3;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #8f9bb3;
  border-radius: 10px;
  border: 0px solid black;
}

/* ----------------------Profile page  -------------------------*/
.change-pass-profil form {
  background: #333d46;
  color: white;
  padding: 23px;
  border-radius: 4px;
}

@media only screen and (min-width: 400px) {
  /* .card-header-custom h6 {
    display: initial;
  }
  .card-header-custom button {
    margin-top: 0px;
  } */
}

@media only screen and (min-width: 768px) {
  /* ----------------------Dashboard left side navbar-------------------------*/
  .dashboard-wrapper {
    display: flex;
  }

  .dashboard-navbar {
    width: 200px;
    min-width: 200px;
    min-height: calc(100vh - 62px);
  }

  .fixed-wrapper {
    position: fixed;
    width: inherit;
    height: inherit;
    background: inherit;
  }

  /* ----------------------Login and register-------------------------*/
  .auth-frame {
    width: 550px;
    margin: 0 auto;
  }

  /* ----------------------Plan card page-------------------------*/
  .plan-card-desc h6 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .container-fluid.main-wrapper {
    padding: 10px 40px;
    // margin-bottom: 100px;
  }

  /* ----------------------Navbar main -------------------------*/
  .header-heading {
    border-bottom: none;
    display: inline;
    padding: initial;
  }

  .nav-item {
    width: initial;
  }

  #navbarNav {
    height: 67px;
  }

  .nav-item.unauth {
    /* justify-content: center; */
    padding-right: initial;
    border-bottom: none;
  }

  .nav-item.nav-right {
    height: 100%;
  }

  .dropDown {
    top: 69px;
  }

  /* ----------------------Plan page-------------------------*/
  .btn-pay-fixed {
    position: absolute;
    right: -190px;
    top: 27px;
  }
}

@media only screen and (min-width: 1200px) {
  /* ----------------------Steper -------------------------*/

  .steper-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .step {
    width: 33%;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .dashboard-navbar {
    background-color: unset;
    height: unset;
  }

  .container-fluid.main-wrapper {
    margin-top: 5rem;
  }

  .dash-navbar li:last-child {
    position: unset;
    transform: unset;
  }
}
