.paginationWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  ul {
    --bs-pagination-bg: unset !important;
    --bs-pagination-color: unset !important;
    --bs-pagination-active-bg: #299bff !important;
    --bs-pagination-active-border-color: unset !important;
    --bs-pagination-disabled-bg: unset !important;
    --bs-pagination-hover-bg: gray !important;
    --bs-pagination-padding-x: 0.5rem !important;
    --bs-pagination-padding-y: 0.2rem !important;
  }

  /* Style the select element */
  select {
    background-color: black;
    color: white; /* Placeholder and values will be white */
    padding: 0 8px;
    border: 1px solid white;
    border-radius: 4px;
    margin-left: 1rem;
    width: 60px; /* Set the width as per your requirement */
    height: 33px; /* Set the height */
    appearance: none; /* Remove default arrow on some browsers */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
  }

  /* Style the dropdown options */
  select option {
    background-color: black;
    color: white;
  }

  /* Style the placeholder text */
  option::placeholder {
    color: white;
  }
}

@media (max-width: 768px) {
  .paginationWrapper {
    flex-direction: column;
    align-items: center;
  }
}
