.wrapper {
  text-align: center;
  .fromWrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    form {
      .inputBox {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        label {
          color: white;
          text-align: start;
        }
        input {
          max-width: 400px;
          border: 1px solid gray !important;
        }
      }
      .radioBtn {
        display: flex;
        justify-content: space-between;
        label {
          input {
            margin-right: 10px;
            cursor: pointer;
          }
          color: white;
          cursor: pointer;
        }
      }
      .formBtn {
        margin-top: 20px !important;
        background-color: #299bff !important;
      }
      .selectBox {
        position: relative;
        @extend .inputBox;
        select {
          cursor: pointer;
          border: 1px solid gray !important;
        }
        .arrowDown {
          position: absolute;
          fill: white;
          left: 90%;
          bottom: 12%;
        }
      }
    }
  }
}
