.oganizationBgColor {
  background-color: #121621 !important;
}

.header {
  justify-content: space-between;
  span {
    padding-top: 5px;
  }
}

.editIcon {
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 8px;
  padding-top: 5px;
  fill: rgb(108, 116, 134);
  cursor: pointer;
}

.qrCodeToolTip {
  z-index: 1;
  max-width: 400px
}

@media only screen and (max-width: 1100px) {
  .planBox {
    padding: 0px !important;
  }
}
