.prceedBtn {
  background-color: #299bff !important;
}

.paymentBox {
  display: flex;
  width: 100% !important;
}

.rowBox {
  width: 100% !important;
}

.planBox {
  width: 50% !important;
  margin-right: 2rem;
}

.modalContent {
  background-color: #121621 !important;
}

@media only screen and (max-width: 1000px) {
    .planBox {
        width: 80% !important;
    }
  .paymentBox {
    flex-direction: column !important;
  }
}

.cardBodyBox {
  max-height: 150px;
}