.wrapper {
  .reactQuillInput {
    max-width: 400px;
  }
}

.ql-editor {
  min-height: 150px;
  padding: 10px;
  color: white;
  background-color: #121621;
}

.quillReact {
  overflow: auto;
  max-height: 100px;
  img {
    max-width: 70%;
  }
}
