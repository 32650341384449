.forgotJumbotron {
  padding: 4rem 2rem !important;
}

.forgotInput {
  background-color: #121621 !important;
  color: white !important;
  border: 0 !important;
}

.sendBtn {
  background: #299bff !important;
  color: white !important;
  border: 0 !important;
}

.textEmail {
    font-size: 15px;
    margin-bottom: 10px;
}
