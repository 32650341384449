.tr:hover {
    cursor: default;
  }
  
  .title {
    letter-spacing: 1px;
    color: #818ea3;
    font-size: 12px;
  }
  
  .header {
    line-height: 1.2rem;
    margin-bottom: 30px;
  }


  .infoWrapper {
    width: 100%;
    .oneRedcord {
      .infoDiv {
        display: flex;
        margin-bottom: 1rem;
        .spanDiv {
          min-width: 185px;
          span {
            color: #299bff;
          }
        }
      }
    }
    .actions {
      .status {
        display: flex;
        margin-bottom: 1rem;
        .spanDiv {
          min-width: 185px;
          span {
            color: #299bff;
          }
        }
      }
      .role {
        display: flex;
        .spanDiv {
          min-width: 185px;
  
          span {
            margin-top: 7px;
            color: #299bff;
          }
        }
  
        select {
          margin-right: 23px;
        }
      }
    }
  }
  
  @media (max-width: 450px) {
    .infoWrapper {
      .oneRedcord {
        .infoDiv {
          display: unset;
        }
      }
      .actions {
        .status {
          display: unset;
        }
        .role {
          display: unset;
          select {
              margin-right: 23px;
            }
        }
      }
    }
  }
  