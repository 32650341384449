.info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  & > div {
    width: 50%;
    display: flex;
    table {
      --bs-table-bg: var(--bs-body-bg) unset !important;
      --bs-table-color: #fff unset !important;
    }
    span {
      margin-left: 1rem;
      font-size: 22px;
    }
  }
  .inputWrapper {
    position: relative;
    input {
      margin-bottom: 10px;
      position: absolute;
      right: 0px;
    }
    .searchIcon {
      position: absolute;
      top: 9px;
      right: 7px;
      cursor: pointer;
    }
  }
}

select {
  background-color: transparent;
  color: white;
  cursor: pointer;
  option {
    background-color: #121621;
    color: white;
    cursor: pointer;
  }
}

@media (max-width: 450px) {
  .info {
    text-align: center;
    flex-direction: column;
    & > div {
      flex-direction: column;
      width: 100%;
    }
    .inputWrapper {
      width: 100%;
      input {
        position: unset;
      }
    }
  }
}
