.wrapper {
  display: flex;
  flex-direction: column;
  .btns {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px 30px 0px;
    button {
      .customNavLink {
        text-decoration: none;
        color: inherit;
      }
    }
  }
  .planWrapper {
    height: 32vh !important;
    overflow: auto;
    background-color: #121621;
    border-radius: 5px;
    margin-bottom: 30px;
    font-size: 14px;
    .singlePlan {
      width: 99.5%;
      background-color: #121621;
      height: 65px;
      display: flex;
      border: 1px solid gray;
      border-radius: 10px;
      margin-bottom: 10px;
      justify-content: space-between;
      .planOptions {
        width: 20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        label {
          width: 100%;
          font-size: 14px;
        }
        p {
          margin-bottom: 0rem !important;
        }
      }
      .editDeleteBtns {
        display: flex;
        justify-content: center;
        align-items: center;
        .customNavLink {
          text-decoration: none;
          color: inherit;
        }
        .icons {
          width: 30px;
          height: 30px;
          margin: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .bundleWrapper {
    @extend .planWrapper;
  }
}

@media only screen and (max-width: 530px) {
  .wrapper {
    .planWrapper {
      .singlePlan {
        display: flex;
        flex-direction: column;
        height: unset;
        padding-top: 10px;
        .planOptions {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: start;
          margin-top: 0px;
          padding-left: 20px;
          font-size: 13px;
          label {
            width: unset;
            max-width: 150px;
            width: 100px;
            text-align: start;
            font-size: 13px;
          }
        }
        .editDeleteBtns {
          .icons {
            width: 20px;
            height: 20px;
            margin: 5px;
          }
        }
      }
    }
    .btns {
      h2 {
        font-size: 20px;
      }
    }
  }
}
