.tr:hover {
  cursor: default;
}

.title {
  letter-spacing: 1px;
  color: #818ea3;
  font-size: 12px;
}

.header {
  line-height: 1.2rem;
  margin-bottom: 30px;
}

@media (max-width: 450px) {
  .usersAndAccountsWrapper {
    & > div {
      ul {
        display: flex;
        justify-content: center;
      }
    }
  }
  .header {
    text-align: center;
  }
}
