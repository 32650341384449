.info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  & > div {
    width: 50%;
    display: flex;
    table {
      --bs-table-bg: var(--bs-body-bg) unset !important;
      --bs-table-color: #fff unset !important;
    }
    span {
      font-size: 22px;
    }
  }
  .totalEarning {
    width: 100%;
    justify-content: end;
    .monthDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2rem;
      padding-bottom: 8px;
      .month {
        font-size: 15px;
        text-align: center;
        width: 130px;
      }
      .icons {
        width: 20px;
        height: 20px;
        margin: 0px 20px;
        cursor: pointer;
        visibility: visible;
      }
      .hideIcon {
        @extend .icons;
        visibility: hidden;
      }
    }
    .total {
      width: 200px;
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 1030px) {
  .info {
    display: flex;
    flex-direction: column;
    .totalEarning {
      justify-content: unset;
      display: flex;
      flex-direction: column;
      .monthDiv {
        justify-content: start;
        padding-top: 8px;
        .icons {
          margin: unset;
        }
      }
    }
  }
}
