.wrapperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailBox {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.spanEdit,
.spanPassword {
  width: 95%;
}

.updateBtn {
  background: #299bff !important;
  color: white !important;
}

.inputBgColor {
  background-color: #121621 !important;
  color: white !important;
}
.changePassBtn {
  width: 100%;
}

.bind {
  display: flex;
  justify-content: space-between;
}
.accountInfo {
  display: flex;
  gap: 15px;
  padding-top: 5px;
}

@media (max-width: 768px) {
  .updateBtn {
    font-size: 9px !important;
    width: 80px !important;
    height: 33px !important;
  }
}