.bgColor {
  background: #121621 !important;
}

.bgLightBlue {
  background-color: #299bff !important;
  color: white !important;
}

.oneRedcord {
  .infoDiv {
    display: flex;
    margin-bottom: 1rem;
    color: white;
    .spanDiv {
      min-width: 185px;
    }
  }
}

@media (max-width: 450px) {
  .oneRedcord {
    .infoDiv {
      display: unset;
    }
  }
  .actions {
    .status {
      display: unset;
    }
    .role {
      display: unset;
      select {
        margin-right: 23px;
      }
    }
  }
}

.cardElement {
  border-radius: 5px;
  padding: 15px;
}
