@import '../../styles/variables.scss';

.infoDiv {
  background-color: $state-info-text;
  padding: 15px;
  border-radius: 10px;
  margin-top: 30px;
  h6 {
    color: $collapsedDark;
  }
  p {
    margin-bottom: 0px;
  }
}

.header {
  line-height: 1.2rem;
  margin-bottom: 30px;
}

.title {
  letter-spacing: 1px;
  color: #818ea3;
  font-size: 12px;
}