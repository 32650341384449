.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .inputWrapper {
    display: flex;
    align-items: center;
    input {
      width: 200px;
    }
    .searchIcon {
      margin-left: -25px;
    }
  }
}
.lines {
  display: flex;
  gap: 5px;
  cursor: unset;
}
.circle {
  width: 10px;
  height: 10px;
  border: 1px solid gray;
  border-radius: 50%;
}
.all {
  background-color: transparent;
}
.empty {
  background-color: transparent;
}
.onHold {
  background-color: blue;
  animation: blink-animation 1s infinite;
}

.inProrgess {
  background-color: green;
}
.qrCodeToolTip {
  z-index: 1;
  max-width: 400px;
}
@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}