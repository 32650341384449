.oneRedcord {
  .infoDiv {
    display: flex;
    margin-bottom: 1rem;
    .spanDiv {
      min-width: 185px;
      span {
        color: #299bff;
      }
    }
  }
}

@media (max-width: 450px) {
  .infoWrapper {
    .oneRedcord {
      .infoDiv {
        display: unset;
      }
    }
    .actions {
      .role {
        display: unset;
        select {
          margin-right: 23px;
        }
      }
    }
  }
}
